@import '~react-multi-carousel/lib/styles.css';

.react-multi-carousel-list .react-multi-carousel-item > *,
.react-multi-carousel-list .react-multi-carousel-item > * > .bg-no-repeat {
  @apply h-full;
}

.react-multi-carousel-dot button {
  @apply border-0 bg-border size-1.5;
}

.react-multi-carousel-dot--active button {
  @apply bg-secondary;
}
